import * as React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import Loading from '../Loading';
import styles from './Button.module.scss';

type VariantType =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'primary-softer-outlined'
    | 'text';

type BaseProps = {
    classes?: typeof styles;
    label?: React.ReactNode;
    loading?: boolean;
    variant?: VariantType;
};

type ButtonAsButton = BaseProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
        as?: 'button';
    };

type ButtonAsLink = BaseProps &
    Omit<LinkProps, keyof BaseProps> & {
        as: 'link';
    };

type ButtonAsExternal = BaseProps &
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps> & {
        as: 'externalLink';
    };

type ButtonProps = ButtonAsButton | ButtonAsExternal | ButtonAsLink;

function Button({
    className,
    classes,
    variant = 'primary',
    ...props
}: ButtonProps): React.ReactElement {
    /* eslint-disable react/button-has-type */
    const mergedClasses = classNames(
        className,
        classes?.root,
        styles.root,
        styles[variant],
    );
    if (props.as === 'button') {
        const { type, label, loading = false, as, ...rest } = props;
        return (
            <button type={type} className={mergedClasses} {...rest}>
                {label}
                {loading && <Loading classes={{ root: styles.loading }} />}
            </button>
        );
    }

    if (props.as === 'link') {
        const { label, as, ...rest } = props;

        return (
            <Link className={mergedClasses} {...rest}>
                {label}
            </Link>
        );
    }

    if (props.as === 'externalLink') {
        const { label, as, ...rest } = props;

        return (
            <a className={mergedClasses} {...rest}>
                {label}
            </a>
        );
    }
    return <></>;
}

export default Button;
